import {
  CenterTableCell,
  CustomTableCell,
  stylesExamResult
} from './UIResources';
import {
  Grid,
  MenuItem,
  Radio,
  Select,
  Table,
  TableBody,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import React, { Component } from 'react';

import { ExamResultUtils } from '../../common/ExamResultUtils';
import { Loading } from '../../ui/Loading';
import PropTypes from 'prop-types';
import authService from '../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { withStyles } from '@material-ui/core/styles';

class ExamResult extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      classInfo: {},
      total: 0,
      examResult: {
        classId: this.props.classInfo.id,
        studentId: this.props.studentInfo.subId,
        examType: 1,
        teacherComment: ''
      },
      examTemplates: [],
      loading: true
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateExamResult();
    this.getExamTemplates().then(examTemplates => {
      this.setState({ examTemplates });
    });
  }

  async populateExamResult() {
    const token = await authService.getAccessToken();
    const { examResult } = this.state;

    const [respExamResult] = await Promise.all([
      fetch(
        `api/AcademicManage/GetExamResultForm/${examResult.classId}/${examResult.studentId}/${examResult.examType}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      )
    ]);
    const [examResultLoad] = await Promise.all([respExamResult.json()]);
    examResultLoad.examResultForm = examResultLoad.examResultJson
      ? JSON.parse(examResultLoad.examResultJson)
      : {};

    this.setState({ examResult: examResultLoad, loading: false }, () => {
      this.calcResult();
    });
  }

  async getExamTemplates() {
    const token = await authService.getAccessToken();
    const { examResult } = this.state;

    const [respExamTemplates] = await Promise.all([
      fetch(`api/AcademicManage/exam-templates/${examResult.classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [examTemplates] = await Promise.all([respExamTemplates.json()]);
    return examTemplates;
  }

  saveExamResult = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const { examResult } = this.state;
    const formBody = JSON.stringify({
      ...examResult,
      examResultJson: JSON.stringify(examResult.examResultForm),
      modifiedBy: loggedInUser.sub
    });
    fetch(`api/AcademicManage/CreateExamResult`, {
      method: 'POST',
      body: formBody,
      headers: headers
    });
  };

  handleChangeType = event => {
    var target = event.target;
    if (target.type === 'radio' && !target.checked) {
      return;
    }

    this.setState(
      prevState => ({
        examResult: {
          ...prevState.examResult,
          [target.name]:
            target.name === 'teacherComment'
              ? target.value
              : parseInt(target.value)
        }
      }),
      () => {
        this.updateStatus(target.name);
      }
    );
  };

  handleChangeScore = (performType, criteria) => event => {
    var target = event.target;
    if (target.type === 'radio' && !target.checked) {
      return;
    }

    switch (performType) {
      case 'overall':
        criteria.evaluation = parseInt(target.value);
        break;
      case 'academic':
        criteria.score = parseInt(target.value);
        break;
      default:
        break;
    }

    const examResult = this.state.examResult;
    const examResultForm = examResult.examResultForm;
    examResultForm[performType].forEach(e => {
      if (e.name === criteria.name) {
        examResultForm[performType][
          examResultForm[performType].indexOf(e)
        ] = criteria;
      }
    });
    examResult.examResultForm = examResultForm;
    this.setState({ examResult }, () => {
      this.calcResult();
    });
  };

  updateStatus = attrName => {
    if (attrName === 'examType') {
      this.setState({ loading: true }, this.populateExamResult);
      return;
    }
  };

  calcResult = () => {
    const { examResult } = this.state;
    if (!examResult.examResultForm.academic) {
      return;
    }

    let sumScore = 0.0;
    let sumWeight = 0.0;
    examResult.examResultForm.academic.forEach(e => {
      sumScore += parseFloat(e.score);
      sumWeight += parseFloat(e.weight);
    });
    const result = (sumScore / sumWeight) * 100;
    this.setState({ total: result.toFixed(2) });
  };

  handleChange = field => value => {
    this.setState(prevState => ({
      examResult: {
        ...prevState.examResult,
        [field]: value
      }
    }));
  };

  render() {
    const { classInfo, studentInfo, classes } = this.props;
    const { examResult, total, loading, examTemplates } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Grid container direction="column">
            <Grid item>
              <Grid container spacing={1} item xs={12}>
                <Grid item xs={4}>
                  <Typography className={classes.headingText}>
                    Class:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.normalText}>
                    {classInfo?.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.headingText}>
                    Student Name:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.normalText}>
                    {studentInfo?.studentName}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.headingText}>
                    English Name:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.normalText}>
                    {studentInfo?.englishName}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.headingText}>
                    Exam Type:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Select
                    className={clsx(classes.normalText, classes.select)}
                    name="examType"
                    value={examResult.examType}
                    onChange={this.handleChangeType}
                  >
                    {examTemplates.map(e => (
                      <MenuItem value={e.examType} key={e.examType}>
                        {e.examName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={clsx(classes.headingText)}>
                Teacher&apos;s comment
              </Typography>
              <ReactQuill
                value={examResult.teacherComment}
                onChange={this.handleChange('teacherComment')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container direction="column">
            <Grid item>
              <Table>
                <TableBody>
                  <TableRow>
                    <CenterTableCell colSpan={5}>
                      <Typography
                        className={clsx(classes.headingText, classes.blueText)}
                      >
                        OVERALL PERFORMANCE
                      </Typography>
                      <Typography
                        className={clsx(classes.headingText, classes.blueText)}
                      >
                        (Đánh giá chung)
                      </Typography>
                    </CenterTableCell>
                  </TableRow>
                  <TableRow>
                    <CenterTableCell rowSpan={2}>
                      <Typography className={classes.headingText}>
                        Skills
                      </Typography>
                      <Typography className={classes.headingText}>
                        (Các kỹ năng)
                      </Typography>
                    </CenterTableCell>
                    <CenterTableCell colSpan={4}>
                      <Typography className={classes.headingText}>
                        Evaluation
                      </Typography>
                      <Typography className={classes.headingText}>
                        (Đánh giá)
                      </Typography>
                    </CenterTableCell>
                  </TableRow>
                  <TableRow>
                    <CenterTableCell width="16%">
                      <Typography className={classes.headingText}>
                        Good
                      </Typography>
                      <Typography className={classes.headingText}>
                        (Tốt)
                      </Typography>
                    </CenterTableCell>
                    <CenterTableCell>
                      <Typography className={classes.headingText}>
                        Fairly Good
                      </Typography>
                      <Typography className={classes.headingText}>
                        (Khá tốt)
                      </Typography>
                    </CenterTableCell>
                    <CenterTableCell width="16%">
                      <Typography className={classes.headingText}>
                        Fair
                      </Typography>
                      <Typography className={classes.headingText}>
                        (Khá)
                      </Typography>
                    </CenterTableCell>
                    <CenterTableCell>
                      <Typography className={classes.headingText}>
                        Need to improve
                      </Typography>
                      <Typography className={classes.headingText}>
                        (Cần cải thiện)
                      </Typography>
                    </CenterTableCell>
                  </TableRow>
                  {loading ? (
                    <TableRow>
                      <CustomTableCell colSpan={5}>
                        <Loading />
                      </CustomTableCell>
                    </TableRow>
                  ) : (
                    examResult.examResultForm.overall &&
                    examResult.examResultForm.overall.map((criteria, idx) => (
                      <TableRow key={idx}>
                        <CustomTableCell>
                          <Typography className={classes.italicText}>
                            {criteria.name}
                          </Typography>
                          <Typography className={classes.italicText}>
                            ({criteria.altName})
                          </Typography>
                        </CustomTableCell>
                        <CenterTableCell>
                          <Radio
                            checked={criteria.evaluation === 4}
                            onChange={this.handleChangeScore(
                              'overall',
                              criteria
                            )}
                            value={4}
                          />
                        </CenterTableCell>
                        <CenterTableCell>
                          <Radio
                            checked={criteria.evaluation === 3}
                            onChange={this.handleChangeScore(
                              'overall',
                              criteria
                            )}
                            value={3}
                          />
                        </CenterTableCell>
                        <CenterTableCell>
                          <Radio
                            checked={criteria.evaluation === 2}
                            onChange={this.handleChangeScore(
                              'overall',
                              criteria
                            )}
                            value={2}
                          />
                        </CenterTableCell>
                        <CenterTableCell>
                          <Radio
                            checked={criteria.evaluation === 1}
                            onChange={this.handleChangeScore(
                              'overall',
                              criteria
                            )}
                            value={1}
                          />
                        </CenterTableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              <Table>
                <TableBody>
                  <TableRow>
                    <CenterTableCell colSpan={3}>
                      <Typography
                        className={clsx(classes.headingText, classes.blueText)}
                      >
                        ACADEMIC PERFORMANCE
                      </Typography>
                      <Typography
                        className={clsx(classes.headingText, classes.blueText)}
                      >
                        (Học thuật)
                      </Typography>
                    </CenterTableCell>
                  </TableRow>
                  <TableRow>
                    <CenterTableCell>
                      <Typography className={classes.headingText}>
                        Linguistic Aspects
                      </Typography>
                      <Typography className={classes.headingText}>
                        (Ngôn ngữ học)
                      </Typography>
                    </CenterTableCell>
                    <CenterTableCell colSpan={2}>
                      <Typography className={classes.headingText}>
                        Score
                      </Typography>
                      <Typography className={classes.headingText}>
                        (Điểm)
                      </Typography>
                    </CenterTableCell>
                  </TableRow>
                  {loading ? (
                    <TableRow>
                      <CustomTableCell colSpan={5}>
                        <Loading />
                      </CustomTableCell>
                    </TableRow>
                  ) : (
                    examResult.examResultForm.academic &&
                    examResult.examResultForm.academic.map((criteria, idx) => (
                      <TableRow key={idx}>
                        <CustomTableCell>
                          <Typography className={classes.italicText}>
                            {criteria.name}
                          </Typography>
                        </CustomTableCell>
                        <CenterTableCell width="20%">
                          <TextField
                            style={{
                              width: 50,
                              height: 20,
                              marginBottom: 8,
                              fontSize: 12
                            }}
                            type="number"
                            value={criteria.score}
                            onChange={this.handleChangeScore(
                              'academic',
                              criteria
                            )}
                          />
                        </CenterTableCell>
                        <CenterTableCell width="20%">
                          <Typography className={classes.headingText}>
                            /{criteria.weight}
                          </Typography>
                        </CenterTableCell>
                      </TableRow>
                    ))
                  )}
                  <TableRow>
                    <CenterTableCell>
                      <Typography className={classes.headingText}>
                        TOTAL
                      </Typography>
                      <Typography className={classes.headingText}>
                        (Tổng)
                      </Typography>
                    </CenterTableCell>
                    <CenterTableCell colSpan={2}>
                      <Typography className={classes.normalText}>
                        {total}%
                      </Typography>
                    </CenterTableCell>
                  </TableRow>
                  <TableRow>
                    <CenterTableCell>
                      <Typography
                        className={clsx(classes.headingText, classes.redText)}
                      >
                        RESULT
                      </Typography>
                      <Typography
                        className={clsx(classes.headingText, classes.redText)}
                      >
                        (Kết quả)
                      </Typography>
                    </CenterTableCell>
                    <CenterTableCell colSpan={2}>
                      <Typography
                        className={clsx(classes.headingText, classes.redText)}
                      >
                        {ExamResultUtils.calcExamResult(total)}
                      </Typography>
                    </CenterTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ExamResult.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesExamResult)(ExamResult);
