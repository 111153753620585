import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import authService from '../../api-authorization/AuthorizeService';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Loading } from '../../ui/Loading';
import { LessonPlanPaths } from './LessonPlanConstants';
import { LessonPlanInfo } from './LessonPlanInfo';
import ActionBtnListItem from '../../ui/list/ActionBtnListItem';
import classNames from 'classnames';
import { fetchHelper } from '../../../helpers/fetch-helper';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class LessonContentComp extends Component {
  static displayName = LessonContentComp.name;

  constructor(...args) {
    super(...args);
    this.levelForm = React.createRef();
    this.newLessonContent = {
      lessonPlanId: this.props.lessonId,
      textbook: '',
      page: '',
      coreWords: '',
      keyWords: '',
      keyQuestions: '',
      keyAnswers: '',
      activities: ''
    };
    this.state = {
      lessonId: this.props.lessonId,
      lessonPlan: {},
      lessonContent: {},
      mediaLinks: [],
      redirect: null,
      loading: true,
      initialLessonPlans: {}
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateLessonPlan();
  }

  populateLessonPlan = async () => {
    const token = await authService.getAccessToken();
    const { lessonId } = this.state;
    const [
      respLessonPlan,
      respLessonContent,
      respDeletedLessonPlans
    ] = await Promise.all([
      fetch(`api/LessonPlans/${lessonId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/LessonPlans/GetLessonContent/${lessonId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/LessonPlans/GetDeletedLessonPlans/${lessonId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [lessonPlan, lessonContent, deletedLessonPlans] = await Promise.all([
      respLessonPlan.json(),
      respLessonContent.status === 204
        ? this.newLessonContent
        : respLessonContent.json(),
      respDeletedLessonPlans.json()
    ]);
    lessonContent.mediaLink = '';
    const mediaLinks = lessonContent.media
      ? JSON.parse(lessonContent.media)
      : [];
    this.setState({
      lessonPlan,
      lessonContent,
      deletedLessonPlans,
      mediaLinks,
      initialLessonPlans: lessonContent,
      loading: false
    });
  };

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      lessonContent: {
        ...prevState.lessonContent,
        [attrName]: attrValue
      }
    }));
  };

  handleChangeQuill = field => value => {
    this.setState(prevState => ({
      lessonContent: {
        ...prevState.lessonContent,
        [field]: value
      }
    }));
  };

  handleAddMedia = () => {
    const { mediaLinks, lessonContent } = this.state;
    mediaLinks.push(lessonContent.mediaLink);
    this.setState({ mediaLinks });
  };

  handleRemoveMedia = url => {
    const { mediaLinks } = this.state;
    const index = mediaLinks.indexOf(url);
    mediaLinks.splice(index, 1);
    this.setState({ mediaLinks });
  };

  onLessonPlanSelected = async event => {
    const { value } = event.target;

    if (value === 'all') {
      // Reset to the initial lesson plans
      this.setState({ lessonContent: this.state.initialLessonPlans });
      return;
    }

    const { id: lessonId } = value;
    try {
      const lessonContent = await this.fetchLessonContent(lessonId);
      delete lessonContent.id;
      this.setState(prevState => ({
        lessonContent: {
          ...lessonContent,
          lessonPlanId: prevState.lessonId,
          lesson: value
        }
      }));
    } catch (error) {
      console.error('Error fetching lesson content:', error);
    }
  };

  fetchLessonContent = async lessonId => {
    return await fetchHelper.get(
      `api/LessonPlans/GetLessonContent/${lessonId}`
    );
  };

  submitData = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const { lessonContent, mediaLinks } = this.state;
    lessonContent.media = JSON.stringify(mediaLinks);
    fetch('api/LessonPlans/SaveLessonContent', {
      method: 'POST',
      body: JSON.stringify(lessonContent),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  backToList() {
    const { lessonPlan } = this.state;
    this.redirectTo(`${LessonPlanPaths.List}/${lessonPlan.levelId}`);
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  renderForm() {
    const { classes } = this.props;
    const { lessonPlan, lessonContent, mediaLinks } = this.state;

    return (
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <div className={clsx(classes.box, classes.center)}>
              <Typography variant="h5" className={classes.header}>
                Lesson Content
              </Typography>
              <Typography variant="h5" className={classes.header}>
                (Nội dung bài học)
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={clsx(classes.box, classes.center)}>
              <Typography variant="h6" className={classes.header}>
                UNIT {lessonPlan.lesson}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box, classes.center)}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="body2" className={classes.header}>
                    Textbook (Sách):
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    className={classes.textfield}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                    name="textbook"
                    value={lessonContent.textbook}
                    onChange={this.handleChange('textbook')}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="body2">Page (Trang):</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    className={classes.textfield}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                    name="page"
                    value={lessonContent.page}
                    onChange={this.handleChange('page')}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box, classes.center)}>
              <Typography variant="body2" className={classes.header}>
                Core words
              </Typography>
              <Typography variant="body2">(Từ bắt buộc)</Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box, classes.center)}>
              <Typography variant="body2" className={classes.header}>
                Key words
              </Typography>
              <Typography variant="body2">(Từ gợi ý)</Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className={clsx(
                classes.box,
                classes.center,
                classes.autoHeightBox
              )}
            >
              <Typography variant="body2" className={classes.header}>
                Vocabulary
              </Typography>
              <Typography variant="body2">(Từ vựng)</Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box, classes.center)}>
              <ReactQuill
                value={lessonContent.coreWords}
                onChange={this.handleChangeQuill('coreWords')}
                className={clsx(classes.margin, classes.quillEditor)}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box, classes.center)}>
              <ReactQuill
                value={lessonContent.keyWords}
                onChange={this.handleChangeQuill('keyWords')}
                className={clsx(classes.margin, classes.quillEditor)}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box, classes.center)}></div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box, classes.center)}>
              <Typography variant="body2" className={classes.header}>
                Key questions
              </Typography>
              <Typography variant="body2">(Câu hỏi chính)</Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box, classes.center)}>
              <Typography variant="body2" className={classes.header}>
                Key answers
              </Typography>
              <Typography variant="body2">(Câu trả lời chính)</Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className={clsx(
                classes.box,
                classes.center,
                classes.autoHeightBox
              )}
            >
              <Typography variant="body2" className={classes.header}>
                Sentence Patterns
              </Typography>
              <Typography variant="body2">(Mẫu câu)</Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box, classes.center)}>
              <ReactQuill
                value={lessonContent.keyQuestions}
                onChange={this.handleChangeQuill('keyQuestions')}
                className={clsx(classes.margin, classes.quillEditor)}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box, classes.center)}>
              <ReactQuill
                value={lessonContent.keyAnswers}
                onChange={this.handleChangeQuill('keyAnswers')}
                className={clsx(classes.margin, classes.quillEditor)}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className={clsx(
                classes.box,
                classes.center,
                classes.autoHeightBox
              )}
            >
              <Typography variant="body2" className={classes.header}>
                Activities
              </Typography>
              <Typography variant="body2">(Hoạt động)</Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className={clsx(classes.box, classes.center)}>
              <ReactQuill
                value={lessonContent.activities}
                onChange={this.handleChangeQuill('activities')}
                className={clsx(classes.margin, classes.quillEditor)}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx(classes.box)}>
              <div className={clsx(classNames.box, classes.center)}>
                <Typography variant="body2" className={classes.header}>
                  Media
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className={clsx(classes.box)}>
              {mediaLinks.length > 0 && (
                <Box
                  border={1}
                  borderRadius={5}
                  padding={0}
                  borderColor="#ddd"
                  className={classes.gridColumn}
                >
                  <List className={classes.scrollList}>
                    {mediaLinks.map((url, idx) => {
                      return (
                        <ActionBtnListItem
                          key={idx}
                          itemValue={url}
                          handleCustomButton={this.handleRemoveMedia}
                        />
                      );
                    })}
                  </List>
                </Box>
              )}
              <TextField
                style={{
                  width: 300
                }}
                className={classes.mediaLinkInput}
                name="mediaLink"
                value={lessonContent.mediaLink}
                onChange={this.handleChange('mediaLink')}
              />
              <IconButton
                size="small"
                color="primary"
                className={classes.button}
                onClick={this.handleAddMedia}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  render() {
    const { classes } = this.props;
    const { deletedLessonPlans, lessonPlan } = this.state;
    return this.state.loading ? (
      <Loading />
    ) : (
      <Fragment>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={2}>
            <FormControl
              className={classNames(
                classes.margin,
                classes.textField,
                classes.dropdownType
              )}
              fullWidth
            >
              <InputLabel htmlFor="deletedLessonPlans">Old</InputLabel>
              <Select
                id="deletedLessonPlans"
                name="deletedLessonPlans"
                value={
                  deletedLessonPlans && deletedLessonPlans.length > 0
                    ? deletedLessonPlans.lesson
                    : ''
                }
                onChange={this.onLessonPlanSelected}
              >
                <MenuItem value="all" key="">
                  All
                </MenuItem>
                {deletedLessonPlans && deletedLessonPlans.length > 0
                  ? deletedLessonPlans.map((plan, index) => (
                      <MenuItem key={index} value={plan}>
                        {`L${index + 1} ${plan.lesson}`}
                      </MenuItem>
                    ))
                  : ''}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <LessonPlanInfo lessonPlan={lessonPlan} />
          </Grid>

          <Grid item xs={12}>
            {this.renderForm()}
          </Grid>

          <Grid item xs={12}>
            {this.state.redirect}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesLessonContent = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1)
  },
  header: {
    fontWeight: 600
  },
  center: {
    textAlign: 'center'
  },
  box: {
    border: '1px solid #d3d4d5',
    minHeight: '100%',
    padding: theme.spacing(1)
  },
  autoHeightBox: {
    height: 'auto',
    minHeight: '250px'
  },
  textfield: {
    marginLeft: theme.spacing(0.5)
  },
  resize: {
    fontSize: 14
  },
  button: {
    margin: theme.spacing(1, 0, 0, 2)
  },
  mediaLinkInput: {
    margin: theme.spacing(0.5, 0, 0, 2)
  },
  scrollList: {
    height: '100%',
    overflowX: 'auto'
  },
  gridColumn: {
    height: 200,
    padding: theme.spacing(0, 1)
  },
  quillEditor: {
    height: '190px'
  }
});

LessonContentComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const LessonContent = withStyles(stylesLessonContent)(LessonContentComp);
