import {
  faAddressCard,
  faCalendarCheck
} from '@fortawesome/free-regular-svg-icons';
import { faArchive, faDonate } from '@fortawesome/free-solid-svg-icons';

import { GeeOMenu } from './MenuItems';
import { MaterialPaths } from '../../material/MaterialConstants';
import React from 'react';
import { Roles } from '../constants/Roles';

const Planning = (
  <GeeOMenu
    expanded={true}
    caption="Planning"
    menuItems={[
      {
        text: 'My Materials',
        link: '/material/by-teacher',
        icon: 'perm_media',
        roles: [Roles.Teacher, Roles.AcadManager]
      },
      {
        text: 'My Lessons',
        link: '/teacher-lesson/list',
        icon: 'library_books',
        roles: [Roles.Teacher, Roles.AcadManager]
      },
      {
        text: 'Levels',
        link: '/study-levels/list',
        icon: 'layers',
        roles: [
          Roles.Admin,
          Roles.Planner,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Materials',
        link: '/material/list',
        icon: 'perm_media',
        roles: [
          Roles.Admin,
          Roles.Planner,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Material Approval',
        link: MaterialPaths.NeedApproval,
        icon: 'rate_review',
        roles: [
          Roles.Admin,
          Roles.Planner,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Teacher Lessons',
        link: '/teacher-lesson/list-all',
        icon: 'library_books',
        roles: [
          Roles.Admin,
          Roles.Planner,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Lesson Search',
        link: '/unit-search/list',
        icon: 'search',
        roles: [
          Roles.Admin,
          Roles.Planner,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      }
    ]}
  />
);

const Academic = (
  <GeeOMenu
    expanded={true}
    caption="Academic"
    menuItems={[
      {
        text: "Let's Learn",
        link: '/my-class/list',
        icon: 'desktop_mac',
        roles: [Roles.Teacher, Roles.AcadManager]
      },
      {
        text: 'My Tasks',
        link: '/task/list',
        icon: 'assignment_late',
        roles: [Roles.Teacher, Roles.AcadManager]
      },
      {
        text: 'My Classes',
        link: '/my-class/preview',
        icon: 'video_library',
        roles: [Roles.Teacher, Roles.AcadManager]
      },
      {
        text: 'Catch-Up',
        link: '/catch-up/list',
        icon: 'restore',
        roles: [Roles.Teacher, Roles.AcadManager]
      },
      {
        text: 'Single Lesson',
        link: '/single-lesson/list',
        icon: 'play_lesson',
        roles: [
          Roles.Admin,
          Roles.SaleManager,
          Roles.SaleExecutive,
          Roles.AcadManager,
          Roles.BranchAdmin,
          Roles.Teacher
        ]
      },
      {
        text: 'Classes',
        link: '/class-course/list',
        icon: 'class',
        roles: [
          Roles.Admin,
          Roles.SaleManager,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Schedule',
        link: '/schedule/list',
        icon: 'schedule',
        roles: [
          Roles.Admin,
          Roles.SaleManager,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Tasks',
        link: '/task/list-all',
        icon: 'assignment_late',
        roles: [
          Roles.Admin,
          Roles.Planner,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Class Proposal',
        link: '/proposal/list',
        icon: 'local_offer',
        roles: [
          Roles.Admin,
          Roles.SaleManager,
          Roles.SaleExecutive,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Management',
        link: '/academic-manage/search',
        icon_fa: faAddressCard,
        roles: [
          Roles.Admin,
          Roles.SaleManager,
          Roles.SaleExecutive,
          Roles.AcadManager,
          Roles.BranchAdmin,
          Roles.Teacher
        ]
      },
      {
        text: 'Payment',
        link: '/student-course/list',
        icon_fa: faDonate,
        roles: [
          Roles.Admin,
          Roles.SaleManager,
          Roles.SaleExecutive,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Reserve',
        link: '/reserve/info/0',
        icon_fa: faArchive,
        roles: [Roles.Admin, Roles.SaleManager, Roles.BranchAdmin]
      }
    ]}
  />
);

const Organization = (
  <GeeOMenu
    expanded={false}
    caption="Organization"
    menuItems={[
      {
        text: 'Teachers',
        link: '/teachers/list',
        icon: 'perm_identity',
        roles: [
          Roles.Admin,
          Roles.SaleManager,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      // {
      //   text: 'Students',
      //   link: '/students/list',
      //   icon: 'assignment_ind',
      //   roles: [Roles.Admin, Roles.SaleManager, Roles.AcadManager]
      // },
      // {
      //   text: 'Parents',
      //   link: '/parents/list',
      //   icon: 'people_alt',
      //   roles: [Roles.Admin, Roles.SaleManager, Roles.AcadManager]
      // },
      {
        text: 'Branches',
        link: '/campus/list',
        icon: 'room',
        roles: [
          Roles.Admin,
          Roles.SaleManager,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Rooms',
        link: '/class-rooms/list',
        icon: 'meeting_room',
        roles: [
          Roles.Admin,
          Roles.SaleManager,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Holidays',
        link: '/settings/holidays',
        icon_fa: faCalendarCheck,
        roles: [
          Roles.Admin,
          Roles.SaleManager,
          Roles.AcadManager,
          Roles.BranchAdmin
        ]
      },
      {
        text: 'Templates',
        link: '/template/list',
        icon: 'import_export',
        roles: [Roles.Admin]
      }
    ]}
  />
);

const Reports = (
  <GeeOMenu
    expanded={false}
    caption="Reports"
    menuItems={[
      {
        text: 'My Class Statistics',
        link: '/report/class/mylist',
        icon: 'chrome_reader_mode',
        roles: [Roles.Teacher, Roles.AcadManager]
      },
      {
        text: 'Absence Notice',
        link: '/catch-up/list-student-cath-up',
        icon: 'restore',
        roles: [Roles.Teacher, Roles.AcadManager]
      },
      {
        text: 'Class Statistics',
        link: '/report/class/list',
        icon: 'chrome_reader_mode',
        roles: [Roles.Admin, Roles.AcadManager, Roles.BranchAdmin]
      }
    ]}
  />
);

const Administration = (
  <GeeOMenu
    expanded={false}
    caption="Administration"
    menuItems={[
      {
        text: 'Users',
        link: '/user/list',
        icon: 'perm_identity',
        roles: [Roles.Admin]
      }
    ]}
  />
);

export { Planning, Academic, Organization, Reports, Administration };
