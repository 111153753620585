import {
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { Component } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { DateTimeUtils } from '../../common/DateTimeUtils';
import { Fragment } from 'react';
import { Loading } from '../../ui/Loading';
import PropTypes from 'prop-types';
import { StudentCourseActions } from '../../student-course/StudentCourseConstants';
import authService from '../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { stylesEditStudent } from '../styleEditStudent';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { fetchHelper } from '../../../helpers/fetch-helper';
import { SecondPayment } from './SecondPayment';

export const PAYMENT_TYPES = [
  { value: '1', label: 'Tiền Mặt' },
  { value: '2', label: 'Chuyển Khoản' },
  { value: '4', label: 'Thanh Toán Bằng Thẻ' },
  { value: '3', label: 'Khác' },
];

export const PaymentTypeMenuItems = PAYMENT_TYPES.map((type) => (
  <MenuItem key={type.value} value={type.value}>
    {type.label}
  </MenuItem>
));

class StudentPayment extends Component {
  constructor(props) {
    super(props);

    this.secondPayment = React.createRef();
    this.paymentTypes = {
      1: 'Khóa 4 tháng',
      2: 'Khóa 6 tháng',
      3: 'Khóa 12 tháng',
      4: 'Khóa 24 tháng',
    };

    this.state = {
      loading: false,
      studentCourse: this.initializeStudentCourse(props),
      classStudent: null,
      campusAssigned: null,
      seUsers: [],
      seUsersByBranch: [],
      classTeacher: [],
      teachers: [],
      installmentPayments: [],
      selectedMainTeacher: null,
      selectedCoTeacher: null,
      selectedCampusAssigned: null,
      selectedUser: null,
    };
  }

  initializeStudentCourse(props) {
    return {
      studentType: props.renew ? '1' : '3',
      transferType: 1,
      name: this.paymentTypes[1],
      studentId: '',
      classId: '',
      startDate: new Date(),
      endDate: new Date(),
      paymentType: 1,
      amount: 0,
      transferTypeDescription: '',
      numberOfSession: 0,
      numberOfDemoSessions: 0,
      mainTeacherId: '',
      coTeacherId: '',
      campusId: '',
      type: 1,
    };
  }

  componentDidMount() {
    const { onRef } = this.props;
    onRef(this);
    this.setState({ loading: true }, this.populatePaymentData);
  }

  populatePaymentData = async () => {
    const { student, stdClass, studentCourseId, action } = this.props;
    const isEdit = action === StudentCourseActions.Edit;
    try {
      const studentCourse = isEdit
        ? await fetchHelper.get(`api/StudentCourses/${studentCourseId}`)
        : {};
      const classStudent = stdClass?.classId
        ? await fetchHelper.get(
            `api/ClassStudents/GetByClass/${stdClass.classId}/${student.id}`,
          )
        : null;

      if (classStudent) {
        studentCourse.studentType = classStudent.studentType;
      }

      const [campusAssigned, seUsers, teachers, classTeacher, studentCourses] =
        await Promise.all([
          stdClass
            ? fetchHelper.get(`api/Campus/GetCampusByClass/${stdClass.classId}`)
            : fetchHelper.get(`api/Campus/assigned`),
          fetchHelper.get(`api/AspNetUsers/sale/branch`),
          fetchHelper.get(`api/AspNetUsers/teacher`),
          stdClass
            ? fetchHelper.get(`api/ClassTeacher/${stdClass.classId}`)
            : null,
          student
            ? fetchHelper.get(
                `api/studentcourses/getstudentpaymentlist/${student.id}`,
              )
            : [],
        ]);
      this.setState((prevState) => ({
        loading: false,
        studentCourse: {
          ...prevState.studentCourse,
          ...studentCourse,
          classId: stdClass?.classId,
          type: !isEdit && studentCourses.length > 0 ? 2 : studentCourse.type,
        },
        classStudent,
        campusAssigned,
        seUsers,
        teachers,
        classTeacher,
        selectedCampusAssigned:
          campusAssigned?.find((x) => x.id === studentCourse.campusId) ||
          campusAssigned?.[0],
        selectedMainTeacher: isEdit
          ? teachers.find((t) => t.id === studentCourse.mainTeacherId)
          : (classTeacher?.find((x) => x.isPrimary) ?? null),
        selectedCoTeacher: isEdit
          ? teachers.find((t) => t.id === studentCourse.coTeacherId)
          : (classTeacher?.find((x) => !x.isPrimary) ?? null),
        selectedUser: isEdit
          ? seUsers.find((u) => u.id === studentCourse.userId)
          : null,
        seUsersByBranch:
          !isEdit && campusAssigned
            ? seUsers.filter((user) =>
                user.branchs.includes(campusAssigned[0].id),
              )
            : seUsers,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({ loading: false });
    }
  };

  saveData = async (student) => {
    const loggedInUser = await authService.getUser();

    await this.savePayment(student, loggedInUser);
    await this.saveClassStudent(student);
  };

  saveClassStudent = async (student) => {
    const { studentCourse } = this.state;
    const classStudent = {
      classId: this.props.classId,
      studentId: student.id,
      studentType: studentCourse.studentType,
      numberOfDemoSessions: studentCourse.numberOfDemoSessions,
    };

    await fetchHelper.post(`api/ClassStudents`, classStudent);
  };

  savePayment = async (student, loggedInUser) => {
    const { studentCourse } = this.state;

    const payments = this.secondPayment.current.getPayments().map((payment) => {
      const { createdAt, ...rest } = payment;
      return rest;
    });
    console.log('second payments: ', payments);
    if (studentCourse.studentType === '3') return; // Not save payment for Demo

    studentCourse.studentId = student.id;
    studentCourse.startDate = DateTimeUtils.toDateTimeJSON(
      studentCourse.startDate,
    );
    studentCourse.mainTeacherId = this.state.selectedMainTeacher?.id;
    studentCourse.coTeacherId = this.state.selectedCoTeacher?.id;
    studentCourse.campusId = this.state.selectedCampusAssigned?.id;
    studentCourse.userId = this.state.selectedUser?.id;
    studentCourse.installmentPayments = payments;

    const url =
      this.props.action === StudentCourseActions.Edit
        ? `api/StudentCourses/${studentCourse.id}/${loggedInUser.sub}`
        : `api/StudentCourses/${loggedInUser.sub}`;

    this.props.action === StudentCourseActions.Edit
      ? await fetchHelper.put(url, studentCourse)
      : await fetchHelper.post(url, studentCourse);
  };

  getHeaders = () => {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  };

  onBranchChange = (_, data) => {
    const { seUsers } = this.state;
    this.setState((prevState) => ({
      seUsersByBranch: seUsers.filter((user) => user.branchs.includes(data.id)),
      studentCourse: {
        ...prevState.studentCourse,
        campusId: data.id,
      },
    }));
  };

  handleDateChange = (name) => (date) => {
    this.setState((prevState) => ({
      studentCourse: {
        ...prevState.studentCourse,
        [name]: date,
      },
    }));
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      (prevState) => ({
        studentCourse: {
          ...prevState.studentCourse,
          [name]: value,
        },
      }),
      () => this.changeNameByPaymentType(name, value),
    );
  };

  changeNameByPaymentType = (name, value) => {
    if (name === 'paymentType') {
      const paymentName = this.paymentTypes[value];
      this.setState((prevState) => ({
        studentCourse: {
          ...prevState.studentCourse,
          name: paymentName,
        },
      }));
    }
  };

  render() {
    const { classes, renew, demoStudent } = this.props;
    const {
      seUsers,
      loading,
      teachers,
      studentCourse,
      campusAssigned,
      seUsersByBranch,
      selectedUser,
      selectedCoTeacher,
      selectedMainTeacher,
      selectedCampusAssigned,
    } = this.state;
    return (
      <Paper className={classes.root}>
        {!renew && !demoStudent && <CardHeader title="Payment" />}
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Grid container justify="space-around">
              <Grid item xs={6}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <FormControl
                      className={clsx(classes.margin, classes.textField)}
                    >
                      <InputLabel htmlFor="student-type">
                        Student type
                      </InputLabel>
                      <Select
                        name="studentType"
                        value={studentCourse.studentType}
                        onChange={this.handleChange}
                      >
                        <MenuItem value="1">Regular</MenuItem>
                        <MenuItem value="2">Catch Up</MenuItem>
                        <MenuItem value="3">Demo</MenuItem>
                      </Select>
                    </FormControl>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid
                        container
                        className={clsx(classes.grid, classes.listItem)}
                        justify="flex-start"
                      >
                        <KeyboardDatePicker
                          margin="normal"
                          label="Start date"
                          format="dd/MM/yyyy"
                          value={studentCourse.startDate}
                          onChange={this.handleDateChange('startDate')}
                          KeyboardButtonProps={{
                            'aria-label': 'change start date',
                          }}
                        />
                        {/* <KeyboardDatePicker
                      margin="normal"
                      label="End Date"
                      format="dd/MM/yyyy"
                      value={studentCourse.endDate}
                      onChange={this.handleDateChange('endDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change end date'
                      }}
                    /> */}
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </ListItem>

                  {studentCourse.studentType === '3' ? (
                    <ListItem className={classes.listItem}>
                      <TextField
                        name="numberOfDemoSessions"
                        style={{ margin: 8 }}
                        fullWidth
                        type="number"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.margin}
                        label="Number of demo sessions"
                        value={studentCourse.numberOfDemoSessions}
                        onChange={this.handleChange}
                      />
                    </ListItem>
                  ) : (
                    <Fragment>
                      <ListItem className={classes.listItem}>
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                        >
                          <InputLabel>Payment Type</InputLabel>
                          <Select
                            name="paymentType"
                            value={studentCourse.paymentType}
                            onChange={this.handleChange}
                          >
                            <MenuItem value="1">4 Months</MenuItem>
                            <MenuItem value="2">6 Months</MenuItem>
                            <MenuItem value="3">12 Months</MenuItem>
                            <MenuItem value="4">24 Months</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          name="numberOfSession"
                          style={{ margin: 8 }}
                          fullWidth
                          type="number"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={classes.margin}
                          label="Number of sessions"
                          value={studentCourse.numberOfSession}
                          onChange={this.handleChange}
                        />
                      </ListItem>
                      <ListItem className={classes.listItem}>
                        <TextField
                          name="amount"
                          style={{ margin: 8 }}
                          fullWidth
                          type="number"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={classes.margin}
                          label="Amount"
                          value={studentCourse.amount}
                          onChange={this.handleChange}
                        />
                      </ListItem>
                    </Fragment>
                  )}
                </List>
              </Grid>
              <Grid item xs={6}>
                <ListItem>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <InputLabel>Transfer type</InputLabel>
                    <Select
                      name="transferType"
                      value={studentCourse.transferType}
                      onChange={this.handleChange}
                    >
                      {PaymentTypeMenuItems}
                    </Select>
                  </FormControl>
                  {studentCourse.transferType?.toString() === '3' && (
                    <TextField
                      name="transferTypeDescription"
                      style={{ margin: 8, width: '80%' }}
                      fullWidth
                      type="text"
                      className={classes.margin}
                      label="Transfer Type Description"
                      value={studentCourse.transferTypeDescription}
                      onChange={this.handleChange}
                    />
                  )}
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <InputLabel>Type</InputLabel>
                    <Select
                      name="type"
                      value={studentCourse.type}
                      onChange={this.handleChange}
                    >
                      <MenuItem value="1">New</MenuItem>
                      <MenuItem value="2">Renew</MenuItem>
                      <MenuItem value="4">Demo</MenuItem>
                      <MenuItem value="5">Scholarship</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    labelPlacement="end"
                    style={{ width: '100%', marginLeft: 6, marginBottom: 10 }}
                    control={
                      <Autocomplete
                        style={{ width: '100%' }}
                        name="campusId"
                        options={campusAssigned || []}
                        getOptionLabel={(option) => option.name}
                        value={selectedCampusAssigned}
                        onChange={this.onBranchChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Branch"
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                      />
                    }
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    style={{ width: '100%', marginLeft: 6, marginBottom: 10 }}
                    control={
                      <Autocomplete
                        style={{ width: '100%' }}
                        options={seUsersByBranch || seUsers}
                        getOptionLabel={(option) => option.fullName}
                        value={selectedUser}
                        onChange={(_, selectedUser) =>
                          this.setState((prev) => ({ ...prev, selectedUser }))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Sale"
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                      />
                    }
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    labelPlacement="end"
                    style={{ width: '100%', marginLeft: 6, marginBottom: 10 }}
                    control={
                      <Autocomplete
                        name="mainTeacherId"
                        style={{ width: '100%' }}
                        options={teachers}
                        getOptionLabel={(option) => option.fullName}
                        value={selectedMainTeacher}
                        onChange={(_, selectedMainTeacher) =>
                          this.setState((prev) => ({
                            ...prev,
                            selectedMainTeacher,
                          }))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Main Teacher"
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                      />
                    }
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    style={{ width: '100%', marginLeft: 6, marginBottom: 10 }}
                    control={
                      <Autocomplete
                        name="coTeacherId"
                        style={{ width: '100%' }}
                        options={teachers}
                        getOptionLabel={(option) => option.fullName}
                        value={selectedCoTeacher}
                        onChange={(_, selectedCoTeacher) =>
                          this.setState((prev) => ({
                            ...prev,
                            selectedCoTeacher,
                          }))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Co-Teacher"
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                      />
                    }
                  />
                </ListItem>
              </Grid>
              <Grid container>
                <SecondPayment
                  ref={this.secondPayment}
                  studentCourseId={studentCourse.id}
                />
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Paper>
    );
  }
}

StudentPayment.propTypes = {
  classes: PropTypes.object.isRequired,
  renew: PropTypes.bool,
  demoStudent: PropTypes.bool,
  stdClass: PropTypes.object,
  student: PropTypes.object,
  studentCourseId: PropTypes.string,
  action: PropTypes.string,
  onRef: PropTypes.func,
  classId: PropTypes.string,
};

export default withStyles(stylesEditStudent)(StudentPayment);
