import React, { Component, Fragment } from 'react';

import AcadReports from './views/AcadReports';
import { AdminPage } from '../ui/page/AdminPage';
import { CLASS_TYPE } from '../class-course/ClassCourseConstants';
import ExamResults from './views/ExamResults';
import { HtmlUtils } from '../common/utils/HtmlUtils';
import { Loading } from '../ui/Loading';
import { Paper } from '@material-ui/core';
import PaymentInfo from './views/PaymentInfo';
import PropTypes from 'prop-types';
import { Roles } from '../common/constants/Roles';
import StudentAppInfo from './views/StudentAppInfo';
import StudentDetail from './views/StudentDetail';
import StudentInfoSection from './views/StudentInfoSection';
import StudentParents from './views/StudentParents';
import authService from '../api-authorization/AuthorizeService';
import { stylesAcademicInfo } from './stylesAcademicInfo';
import { stylesCharting } from '../class-course/reports/stylesCharting';
import { withStyles } from '@material-ui/core/styles';
import { fetchHelper } from '../../helpers/fetch-helper';

class AcademicInfo extends Component {
  static displayName = AcademicInfo.name;

  constructor(...args) {
    super(...args);
    this.state = {
      studentId: this.props?.match?.params?.studentId,
      student: {},
      stdPaymentInfo: {},
      parents: [],
      loading: true,
      pageTitle: 'Academic Information',
      classSelected: null,
    };
  }

  componentDidMount() {
    this.populateStudentData();
  }

  populateStudentData = async () => {
    const token = await authService.getAccessToken();
    const loggedInUser = await authService.getUser();
    const { studentId } = this.state;

    const [
      respStudent,
      respParent,
      respStdClassList,
      respStdPayments,
      respUser,
    ] = await Promise.all([
      fetch(`api/Student/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      }),
      fetch(`api/StudentParents/getParentByStudentIds/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      }),
      fetch(`api/Student/GetClassList/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      }),
      fetch(`api/StudentCourses/GetStudentPaymentInfo/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      }),
      fetch(`api/AspNetUsers/GetUserRole/${loggedInUser?.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      }),
    ]);
    const [student, dataParents, stdClassList, stdPaymentInfo, dataUser] =
      await Promise.all([
        respStudent.json(),
        respParent.json(),
        respStdClassList.json(),
        respStdPayments.json(),
        respUser.json(),
      ]);

    let parents = [];
    if (dataParents) {
      parents[0] =
        dataParents[0] && dataParents[0].relation === 1
          ? dataParents[0]
          : dataParents[1] && dataParents[1].relation === 1
            ? dataParents[1]
            : null;
      parents[1] =
        dataParents[0] && dataParents[0].relation === 2
          ? dataParents[0]
          : dataParents[1] && dataParents[1].relation === 2
            ? dataParents[1]
            : null;
    }
    student.traits = HtmlUtils.newlineToHtml(student.traits);

    const secondPayments = await this.groupSecondPaymentsByPaymentId(
      stdPaymentInfo.studentPaymentList,
    );
    this.setState({
      student,
      parents,
      stdClassList,
      stdPaymentInfo,
      dataUser,
      loading: false,
      secondPayments,
    });
  };

  groupSecondPaymentsByPaymentId = async (payments) => {
    const grouped = {};

    for (const item of payments) {
      const secondPayments = await this.getSecondPaymentList(item.id);
      grouped[item.id] = secondPayments || [];
    }

    return grouped;
  };

  getSecondPaymentList = async (id) => {
    return await fetchHelper.get(`api/InstallmentPayments/list/${id}`);
  };

  classSelectedCallback = async (_class) => {
    this.setState({ classSelected: _class });
  };

  renderContent() {
    const { classes } = this.props;
    const {
      student,
      studentId,
      stdClassList,
      parents,
      stdPaymentInfo,
      dataUser,
      classSelected,
      secondPayments,
    } = this.state;

    const regularClass = stdClassList.find(
      (item) => item?.studentType === CLASS_TYPE.Regular,
    );

    const suspendedClasses = stdClassList.filter(
      (item) => item?.studentType === CLASS_TYPE.Suspended,
    );
    const suspendedClass = suspendedClasses[suspendedClasses.length - 1];
    const roleName = dataUser?.role?.name;
    const actionAllowedAccess = {
      EditStudent: roleName !== Roles.Teacher,
      Renew: roleName !== Roles.Teacher,
      Reserve: roleName !== Roles.Teacher,
      Payment: roleName === Roles.Admin,
    };

    return (
      <Fragment>
        <Paper>
          {/* {StudentInfoSection(this, classes, studentId, stdClassList[0])} */}
          {StudentInfoSection(
            this,
            classes,
            studentId,
            regularClass,
            actionAllowedAccess,
          )}
          {/* <StudentDetail student={student} stdClassList={stdClassList} /> */}
          <StudentDetail
            student={student}
            stdClassList={[regularClass, suspendedClass]}
          />
          {StudentParents(classes, parents)}
          {PaymentInfo(
            classes,
            student,
            stdPaymentInfo,
            actionAllowedAccess,
            secondPayments,
          )}
          <AcadReports
            classes={classes}
            student={student}
            stdClassList={stdClassList}
            callback={this.classSelectedCallback}
          />
          <ExamResults
            key={
              classSelected?.classId ||
              regularClass?.classId ||
              (stdClassList && stdClassList[0]?.classId)
            }
            classesBase={classes}
            student={student}
            // stdClass={stdClassList[0]}
            stdClass={
              stdClassList.find(
                (item) => item?.classId === classSelected?.classId,
              ) ||
              regularClass ||
              (stdClassList && stdClassList[0])
            }
          />
          <StudentAppInfo
            classesBase={classes}
            student={student}
            // stdClass={stdClassList[0]}
            stdClass={regularClass || (stdClassList && stdClassList[0])}
          />
        </Paper>
      </Fragment>
    );
  }

  render() {
    const { pageTitle, loading } = this.state;
    let content = loading ? <Loading /> : this.renderContent();

    return (
      <Fragment>
        <AdminPage title={pageTitle} content={content} />
      </Fragment>
    );
  }
}

const stylesAcadInfo = (theme) => ({
  ...stylesCharting(theme),
  ...stylesAcademicInfo(theme),
});

AcademicInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(stylesAcadInfo)(AcademicInfo);
