import { LessonPlanActions, LessonPlanPaths } from './LessonPlanConstants';
import {
  MessageBarText,
  MessageBarVariants,
  MessageBars,
  MessageType
} from './../../ui/popup/Messages';
import React, { Component, Fragment } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../../ui/Loading';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import authService from '../../api-authorization/AuthorizeService';
import classNames from 'classnames';
import { fetchHelper } from '../../../helpers/fetch-helper';
import { withStyles } from '@material-ui/core/styles';

const stylesEditLessonPlan = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  }
});

class EditLessonPlanComp extends Component {
  static displayName = EditLessonPlanComp.name;

  constructor(...args) {
    super(...args);
    this.levelForm = React.createRef();
    this.messageBar = React.createRef();
    this.state = {
      action: this.props.action,
      lessonId: this.props.lessonId,
      levelId: this.props.levelId,
      method: 'PUT',
      lessonPlan: {},
      studyLevels: [],
      redirect: null,
      loading: true,
      loadingLevels: true,
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Success}
          type={MessageType.Upload}
          message={MessageBarText.Success.upload}
          handleClose={this.handleCloseMsgBar}
        />
      )
    };
    switch (this.props.action) {
      case LessonPlanActions.Create:
        this.state.lessonPlan = {
          levelId: this.state.levelId ? this.state.levelId : '',
          lesson: '',
          subject: '',
          content: '',
          tb: ''
        };
        this.state.method = 'POST';
        this.state.action = `api/LessonPlans`;
        this.state.loading = false;
        break;
      case LessonPlanActions.Edit:
        this.state.action = `api/LessonPlans/${this.state.lessonId}`;
        this.populateLessonPlanData();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateLevelData();
  }

  async populateLevelData() {
    const token = await authService.getAccessToken();
    const response = await fetch(`api/StudyLevels`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ studyLevels: data, loadingLevels: false });
  }

  async populateLessonPlanData() {
    const token = await authService.getAccessToken();
    const { lessonId } = this.state;
    const response = await fetch(`api/LessonPlans/${lessonId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ lessonPlan: data, loading: false });
  }

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      lessonPlan: {
        ...prevState.lessonPlan,
        [attrName]: attrValue
      }
    }));
  };

  backToList() {
    const { lessonPlan } = this.state;
    this.redirectTo(`${LessonPlanPaths.List}/${lessonPlan.levelId}`);
  }

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.levelForm.current).dispatchEvent(
      new Event('submit')
    );
  }

  handleSubmit = async e => {
    e.preventDefault();
    const isValid = await this.checkExistsOrExceedsByLesson();
    if (isValid) {
      this.showMessageBar(
        MessageBarVariants.Error,
        MessageType.Submit,
        `The lesson number already exists in the plan or exceeds the number of lesson in the level.`
      );
      return;
    }

    const { lessonPlan, method, action, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    try {
      const response = await fetch(action, {
        method: method,
        body: JSON.stringify(lessonPlan),
        headers: headers
      });
      if (response.ok) {
        this.backToList();
      } else throw new Error(response.status);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  checkExistsOrExceedsByLesson = async () => {
    const { lessonPlan } = this.state;
    const id = lessonPlan?.id ? lessonPlan.id : '';
    const res = await fetchHelper.get(
      `api/LessonPlans/${lessonPlan.levelId}/${lessonPlan.lesson}/${id}`
    );
    return res;
  };

  showMessageBar = (msgVariant, msgType, msgText) => {
    this.messageBar.showMessage(msgVariant, msgType, msgText);
  };

  handleCloseMsgBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  renderForm() {
    const { classes } = this.props;
    const { lessonPlan, studyLevels } = this.state;
    const cols = [
      {
        name: 'levelId',
        header: 'Level',
        placeholder: 'Level',
        adornment: ''
      },
      {
        name: 'lesson',
        header: 'Lesson',
        placeholder: 'Lesson',
        adornment: ''
      },
      {
        name: 'subject',
        header: 'Subject',
        placeholder: 'Subject',
        adornment: ''
      },
      {
        name: 'content',
        header: 'Content',
        placeholder: 'Content',
        adornment: ''
      },
      {
        name: 'tb',
        header: 'TB',
        placeholder: '',
        adornment: ''
      }
    ];

    return (
      <Paper className={classes.root}>
        <form
          ref={this.levelForm}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <FormControl
                className={classNames(classes.margin, classes.textField)}
              >
                <InputLabel htmlFor="level-id">{cols[0].header}</InputLabel>
                <Select
                  id="level-id"
                  name={cols[0].name}
                  value={lessonPlan[cols[0].name]}
                  onChange={this.handleChange(cols[0].name)}
                >
                  {studyLevels.map(row => {
                    return (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem className={classes.listItem}>
              <FormControl
                className={classNames(classes.margin, classes.textField)}
              >
                <InputLabel htmlFor="lesson">{cols[1].header}</InputLabel>
                <Input
                  id="lesson"
                  name={cols[1].name}
                  value={lessonPlan[cols[1].name]}
                  onChange={this.handleChange(cols[1].name)}
                  endAdornment={
                    <InputAdornment position="end">
                      {cols[1].adornment}
                    </InputAdornment>
                  }
                  inputProps={{
                    'aria-label': cols[1].header
                  }}
                />
              </FormControl>
            </ListItem>
            <ListItem className={classes.listItem}>
              <TextField
                id="subject"
                style={{ margin: 8 }}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                className={classes.margin}
                label={cols[2].header}
                placeholder={cols[2].placeholder}
                name={cols[2].name}
                value={lessonPlan[cols[2].name]}
                onChange={this.handleChange(cols[2].name)}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <TextField
                id="content"
                fullWidth
                multiline
                rows="4"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                className={classes.margin}
                label={cols[3].header}
                placeholder={cols[3].placeholder}
                name={cols[3].name}
                value={lessonPlan[cols[3].name]}
                onChange={this.handleChange(cols[3].name)}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <FormControl
                className={classNames(classes.margin, classes.textField)}
              >
                <InputLabel htmlFor="lesson-tb">{cols[4].header}</InputLabel>
                <Input
                  id="lesson-tb"
                  name={cols[4].name}
                  value={lessonPlan[cols[4].name]}
                  onChange={this.handleChange(cols[4].name)}
                  endAdornment={
                    <InputAdornment position="end">
                      {cols[4].adornment}
                    </InputAdornment>
                  }
                  inputProps={{
                    'aria-label': cols[4].header
                  }}
                />
              </FormControl>
            </ListItem>
          </List>
        </form>
        {this.state.redirect}
        {this.state.messageBar}
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();

    return <Fragment>{contents}</Fragment>;
  }
}

EditLessonPlanComp.propTypes = {
  classes: PropTypes.object.isRequired,
  action: PropTypes.string,
  lessonId: PropTypes.string,
  levelId: PropTypes.string,
  onRef: PropTypes.func
};

export const EditLessonPlan = withStyles(stylesEditLessonPlan)(
  EditLessonPlanComp
);
