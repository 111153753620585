export const stylesDashboard = theme => ({
  table: {
    minWidth: 300
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  },
  extendedFab: {
    padding: theme.spacing(0, 2),
    fontSize: 12
  },
  extendedIcon: {
    margin: theme.spacing(0, 0.5),
    fontSize: 14
  },
  actionIcon: {
    margin: 3
  },
  yellowBtn: {
    backgroundColor: '#efe934',
    '&:hover': {
      backgroundColor: '#efe934'
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  tableCell: {
    fontSize: 12,
    padding: 10,
    minWidth: 92,
    maxWidth: 200
  },
  boldText: {
    fontFamily: 'RalewayBold'
  },
  notifyAreaWrap: {
    minHeight: 300
  },
  notifyContentWrap: {
    padding: theme.spacing(0.5, 2),
    maxHeight: 690,
    overflowX: 'auto'
  },
  notifyItemWrap: {
    display: 'flex',
    margin: theme.spacing(0.5, 0),
    cursor: 'pointer'
  },
  notifyIndicator: {
    display: 'flex',
    width: 8,
    borderRadius: '4px 0 0 4px'
  },
  notifyItem: {
    display: 'flex',
    padding: theme.spacing(2),
    width: '100%'
  },
  notifyTitle: {
    flex: '1 1 100%',
    marginRight: theme.spacing(2)
  },
  notifySubject: {
    flex: '1 1 100%',
    marginRight: theme.spacing(2)
  },
  notifyActivity: {
    flex: '1 1 100%',
    marginRight: theme.spacing(2)
  },
  notifyItemIndented: {
    marginLeft: theme.spacing(4)
  }
});
