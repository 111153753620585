import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import React, { Component, Fragment } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../ui/Loading';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { SchedulePaths } from './ScheduleConstans';
import Select from '@material-ui/core/Select';
import authService from '../api-authorization/AuthorizeService';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const stylesList = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'end'
  },
  margin: {
    margin: theme.spacing(1)
  },
  marginB20: {
    marginBottom: '20px'
  },
  textField: {
    width: 300
  },
  legend: {
    marginBottom: theme.spacing(2)
  },
  longTextField: {
    width: '100%'
  },
  formControl: {
    margin: theme.spacing(3, 3, 3, 5)
  },
  fullWidth: {
    width: '100%'
  }
});
moment.locale('en');
const localizer = momentLocalizer(moment);

class CalendarScheduleComp extends Component {
  static displayName = CalendarScheduleComp.name;

  constructor(...args) {
    super(...args);
    this.state = {
      loading: true,
      redirect: null,
      scheduleId: this.props?.scheduleId,
      classId: this.props?.classId,
      isMySchedule: this.props?.isMySchedule,
      viewAllClass: this.props?.viewAllClass,
      viewMode: this.props?.viewMode,
      data: [],
      dataCampus: [],
      objFilter: {
        campusId: '',
        title: ''
      },
      filteredRows: []
    };
  }

  componentDidMount() {
    this.populateDataInit();
    this.props.onRef(this);
  }

  backToList() {
    this.redirectTo(SchedulePaths.List);
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;
    this.setState(
      prevState => ({
        objFilter: {
          ...prevState.objFilter,
          [attrName]: attrValue
        }
      }),
      this.setChildData
    );
  };

  populateDataInit = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const { isMySchedule, scheduleId, classId, viewAllClass } = this.state;
    var dataResponse = [];
    if (viewAllClass) {
      if (isMySchedule) {
        const loggedInUser = await authService.getUser();
        const response = await fetch(
          `api/Schedules/GetAllDataCalendarByUserId/${loggedInUser.sub}`,
          {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
          }
        );
        dataResponse = await response.json();
      } else {
        const response = await fetch(`api/Schedules/GetAllDataCalendar`, {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });
        dataResponse = await response.json();
      }
    } else {
      if (isMySchedule) {
        const response = await fetch(
          `api/Schedules/GetDataCalendarByClass/${classId}`,
          {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
          }
        );
        dataResponse = await response.json();
      } else {
        const response = await fetch(
          `api/Schedules/GetDataCalendarBySchedule/${scheduleId}`,
          {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
          }
        );
        dataResponse = await response.json();
      }
    }
    const responseCampus = await fetch(
      `api/Campus/GetCampusList/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const dataCampus = await responseCampus.json();

    var eventData = [];
    dataResponse.forEach(function(e) {
      let startTime = new Date(e.startTime);
      let endTime = new Date(e.endTime);
      let objCalendar = {
        id: e.id,
        campusId: e.campusId,
        title: e.title,
        start: new Date(
          startTime.getFullYear(),
          startTime.getMonth(),
          startTime.getDate(),
          startTime.getHours(),
          startTime.getMinutes()
        ),
        end: new Date(
          endTime.getFullYear(),
          endTime.getMonth(),
          endTime.getDate(),
          endTime.getHours(),
          endTime.getMinutes()
        )
      };
      eventData.push(objCalendar);
    });
    this.setState({ data: eventData, loading: false, dataCampus: dataCampus });
    this.setChildData();
  };

  setChildData = () => {
    const { objFilter, data } = this.state;
    const filteredRows = data.filter(
      ({ campusId, title }) =>
        (!objFilter.campusId || campusId === objFilter.campusId) &&
        (!objFilter.title ||
          title.toLowerCase().includes(objFilter.title.toLowerCase()))
    );
    this.setState({ filteredRows, loading: false });
  };

  renderCalendar = () => {
    const { filteredRows, viewMode } = this.state;
    return (
      <Calendar
        events={filteredRows}
        step={60}
        culture={'en'}
        localizer={localizer}
        defaultView={viewMode ?? Views.MONTH}
        style={{ height: 500, width: '100%', paddingBottom: '10px' }}
      />
    );
  };

  render() {
    const { objFilter, dataCampus } = this.state;
    const { classes, viewAllClass } = this.props;
    let contents = this.state.loading ? <Loading /> : this.renderCalendar();

    return (
      <Fragment>
        {viewAllClass ? (
          <Paper className={`${classes.marginB20} ${classes.fullWidth}`}>
            <Grid container>
              {/* <Grid item xs={6} className={classes.cell}></Grid> */}
              <Grid item xs={6} className={classes.cell}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <FormControl
                      className={classNames(classes.margin, classes.textField)}
                    >
                      <InputLabel htmlFor="campus-id">Campus</InputLabel>
                      <Select
                        id="campus-id"
                        name="campus"
                        value={objFilter['campusId']}
                        onChange={this.handleChange('campusId')}
                      >
                        <MenuItem value="" key="">
                          All
                        </MenuItem>
                        {dataCampus.map(row => {
                          return (
                            <MenuItem value={row.id} key={row.id}>
                              {row.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} className={classes.cell}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="search-campus"
                      name="title"
                      placeholder="Search title..."
                      variant="outlined"
                      fullWidth
                      value={objFilter['title']}
                      onChange={this.handleChange('title')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        )
                      }}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>
        ) : null}
        {contents}
        {this.state.redirect}
      </Fragment>
    );
  }
}

CalendarScheduleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const CalenderSchedule = withStyles(stylesList)(CalendarScheduleComp);
