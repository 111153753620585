import React, { Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { Template } from './Template';
import { TemplateActions, TemplatePaths } from './TemplateConstans';

const TemplateRoutes = () => {
  return (
    <Fragment>
      <PropsRoute
        path={TemplatePaths.List}
        component={Template}
        action={TemplateActions.List}
      />
    </Fragment>
  );
};

export default TemplateRoutes;
