import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Typography,
  FormGroup,
} from '@material-ui/core';
import {
  AddBoxOutlined,
  RemoveCircleOutlineOutlined,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { fetchHelper } from '../../../helpers/fetch-helper';
import { PAYMENT_TYPES, PaymentTypeMenuItems } from './StudentPayment';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  inline: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
  },
  btnPlus: {
    backgroundColor: 'white',
  },
});

const SecondPaymentComp = forwardRef(({ classes, studentCourseId }, ref) => {
  const [payments, setPayments] = useState([]);

  useImperativeHandle(ref, () => ({
    getPayments: () => payments.filter((p) => p.amount > 0),
  }));

  useEffect(() => {
    if (!studentCourseId) return;
    getPayments().then(setPayments);
  }, [studentCourseId]);

  const getPayments = async () => {
    return await fetchHelper.get(
      `api/InstallmentPayments/list/${studentCourseId}`,
    );
  };

  const onPaymentChange = (e, index) => {
    e.preventDefault();
    const { name, value } = e.target;
    const list = [...payments];
    list[index][name] = value;
    setPayments(list);
  };

  const onRemove = (index) => {
    const list = [...payments];
    list.splice(index, 1);
    setPayments(list);
  };

  const onPaymentAdd = () => {
    setPayments((payments) => [
      ...payments,
      {
        amount: '0',
        transferType: PAYMENT_TYPES[0].value,
        transferTypeDescription: '',
        studentCourseId,
      },
    ]);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.inline}>
        <Typography variant="p" gutterBottom>
          Second Payment
        </Typography>
        <IconButton
          aria-label="add"
          className={classes.btnPlus}
          onClick={onPaymentAdd}
        >
          <AddBoxOutlined />
        </IconButton>
      </div>
      <FormGroup>
        {payments.map((payment, index) => (
          <Grid item xs={12} className={classes.inline} key={index}>
            <TextField
              name="amount"
              fullWidth
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.margin}
              label="Amount"
              value={payment.amount}
              onChange={(e) => onPaymentChange(e, index)}
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel>Transfer type</InputLabel>
              <Select
                name="transferType"
                value={payment.transferType}
                onChange={(e) => onPaymentChange(e, index)}
                label="Transfer type"
              >
                {PaymentTypeMenuItems}
              </Select>
            </FormControl>

            {payment.transferType.toString() === '3' && (
              <TextField
                name="transferTypeDescription"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
                label="Transfer Type Description"
                value={payment.transferTypeDescription}
                onChange={(e) => onPaymentChange(e, index)}
              />
            )}

            <IconButton
              className={classes.btnMinus}
              onClick={() => onRemove(index)}
            >
              <RemoveCircleOutlineOutlined />
            </IconButton>
          </Grid>
        ))}
      </FormGroup>
    </Paper>
  );
});

SecondPaymentComp.propTypes = {
  classes: PropTypes.object.isRequired,
  studentCourseId: PropTypes.string.isRequired,
};

SecondPaymentComp.displayName = 'SecondPayment';

export const SecondPayment = withStyles(styles)(SecondPaymentComp);
