import React, { Fragment } from 'react';
import { AdminPage } from '../ui/page/AdminPage';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { ListTemplate } from './ListTemplate';

const TemplateComp = () => {
  return (
    <Fragment>
      <AdminPage title={'Template'} content={<ListTemplate />} />
    </Fragment>
  );
};

TemplateComp.propTypes = {
  action: PropTypes.object.isRequired
};

export const Template = withStyles()(TemplateComp);
