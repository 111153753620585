import React, { Fragment, useState, useRef, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';
import CustomizedTable from '../ui/table/CustomizedTable';
import { fetchHelper } from '../../helpers/fetch-helper';
import { Loading } from '../ui/Loading';
import PropTypes from 'prop-types';
import { TemplateDialog } from './TemplateDialog';

const stylesListTemplate = () => ({});
const ListTemplateComp = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const childRef = useRef();

  useEffect(() => {
    populateDateInit();
  }, []);

  const populateDateInit = async () => {
    try {
      const res = await fetchHelper.get(`api/Templates/list`);
      setData(res.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch data', error);
      setLoading(false);
    }
  };

  const eventReloadData = () => {
    populateDateInit();
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDialog = rowData => {
    setSelectedRowId(rowData);
    setIsDialogOpen(true);
  };

  const handleSaveDialog = formData => {
    setData(formData);
    closeDialog();
    eventReloadData();
  };

  const renderDatagrid = () => {
    const cols = [
      { name: 'functionName', header: 'FuncTionName', align: 'right' },
      { name: 'templateName', header: 'TemplateName', align: 'right' },
      { name: 'templateId', header: 'TemplateId', align: 'right', width: 550 },
      { name: 'notes', header: 'Notes', align: 'right' }
    ];
    const action = [
      {
        name: 'callback',
        callBackFn: openDialog,
        tooltip: 'Add file',
        icon: 'add_box'
      }
    ];

    return (
      <Fragment>
        <CustomizedTable
          onRef={actualChild => (childRef.current = actualChild)}
          rows={data}
          cols={cols}
          actions={action}
          eventReloadData={eventReloadData}
          isPaging={true}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Paper>{loading ? <Loading /> : renderDatagrid()}</Paper>
      <TemplateDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onSave={handleSaveDialog}
        selectedRowId={selectedRowId}
      />
    </Fragment>
  );
};

ListTemplateComp.propTypes = {
  action: PropTypes.object.isRequired
};

export const ListTemplate = withStyles(stylesListTemplate)(ListTemplateComp);
