import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { fetchHelper } from '../../helpers/fetch-helper';

const stylesTemplateDialog = () => {};
const TemplateDialogComp = ({ open, onClose, onSave, selectedRowId }) => {
  const [templateName, setTemplateName] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [notes, setNotes] = useState('');

  const handleSave = () => {
    const formData = {
      id: selectedRowId.id,
      functionName: selectedRowId.functionName,
      templateName,
      templateId,
      notes
    };
    saveFile(formData);
    onSave(formData);
    onClose();
  };

  const handleFileChange = async event => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    } else {
      return;
    }
    const formData = new FormData();
    formData.append('template', file);
    try {
      const response = await fetch('https://api.carbone.io/template', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CARBONE_TOKEN}`
        }
      });
      const result = await response.json();
      const templateId = result.data.templateId;
      setTemplateId(templateId);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const saveFile = async data => {
    try {
      const res = await fetchHelper.put(`api/Templates/${data.id}`, data);
      return res;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleInputChange = (event, setter) => {
    setter(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {selectedRowId
          ? `Function Name: ${selectedRowId.functionName}`
          : 'Function Name'}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Template Name"
          value={templateName}
          onChange={e => handleInputChange(e, setTemplateName)}
          fullWidth
          margin="normal"
        />
        {selectedFile && (
          <TextField
            label="File Template"
            value={selectedFile.name}
            onChange={e => handleInputChange(e, setTemplateId)}
            fullWidth
            margin="normal"
          />
        )}

        <Button variant="contained" component="label">
          Choose File
          <input
            type="file"
            onChange={handleFileChange}
            hidden
            accept=".doc,.docx"
          />
        </Button>

        <TextField
          label="Notes"
          value={notes}
          onChange={e => setNotes(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          minRows="3"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TemplateDialogComp.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedRowId: PropTypes.string.isRequired
};

export const TemplateDialog = withStyles(stylesTemplateDialog)(
  TemplateDialogComp
);
